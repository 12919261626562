@import "@styles/constants";

.ptsPage {
}

.menu {
  text-align: right;
  margin-bottom: 20px;
}

.group {
  background: rgb(26, 30, 35);
  padding: 20px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;
  display: flex;

  &:hover {
    background: rgba(26, 30, 35, 0.2);
  }

  &:visited {
    color: inherit;
  }
}
